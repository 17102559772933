import React, { useContext, useEffect, useRef, useState } from "react";
import userPlaceholder from "../assets/user-placehoder.png";
import addImage from "../assets/add-image.png";
import { useDispatch } from "react-redux";
import { EmpManagementContext } from "../context/EmpManagementContext";
import axiosInstance from "../interceptor/axiosInstance";
import { Visibility, VisibilityOff } from "@mui/icons-material"; 
import { CircularProgress } from "@mui/material";


const CreateUser = ({ open, setOpen }) => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    role: "",
    mobile_no: "",
    password: "",
    profilePic: "",
  });
  const [errMsg, setErrMsg] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const imageRef = useRef();

  const { editUserDetails, setEditUserDetails, fetchUsers } =
    useContext(EmpManagementContext);

  useEffect(() => {
    if (editUserDetails) {
      setUserDetails(editUserDetails);
    }
  }, [editUserDetails]);

  const { setCreateUser } = useContext(EmpManagementContext);
  const dispatch = useDispatch();

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        setShowProgress(true);
        const response = await axiosInstance.post(
          "/api/employee/imageUpload",
          formData
        );
        const data = response.data[0];
        setUserDetails((prev) => ({
          ...prev,
          profilePic: data,
        }));
        if (editUserDetails) {
          setEditUserDetails((prev) => ({
            ...prev,
            user_pic: data,
          }));
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setShowProgress(false);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (editUserDetails) {
      setEditUserDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleRemoveImg = () => {
    if (editUserDetails) {
      setEditUserDetails((prev) => ({
        ...prev,
        user_pic: "",
      }));
    } else {
      setUserDetails((prev) => ({
        ...prev,
        profilePic: "",
      }));
    }
  };

  const handleReset = () => {
    setUserDetails({
      name: "",
      email: "",
      role: "",
      mobile_no: "",
      password: "",
      profilePic: "",
    });
    setEditUserDetails({});
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^[0-9]{10}$/;
    return phonePattern.test(phone);
  };

  const validatePassword = (password) => {
    const passwordPattern = /^(?=.*[A-Z])(?=.*[\W_]).{8,}$/; // At least 8 characters, 1 uppercase letter, and 1 special character
    return passwordPattern.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate email, phone number, and password
    if (!validateEmail(userDetails.email)) {
      setErrMsg("Invalid email format");
      return;
    }
    if (!validatePhoneNumber(userDetails.mobile_no)) {
      setErrMsg("Phone number must be exactly 10 digits");
      return;
    }
    if (!validatePassword(userDetails.password)) {
      setErrMsg(
        "Password must be at least 8 characters long, contain one uppercase letter and one special character"
      );
      return;
    }

    setErrMsg(""); // Clear any previous error messages

    if (editUserDetails) {
      try {
        const response = await axiosInstance.put(
          "/api/employee/edit/profile",
          editUserDetails
        );
        setOpen("Update Details");
        setEditUserDetails(null);
        setCreateUser(false);
        fetchUsers();
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const response = await axiosInstance.post(
          "/api/employee/creation",
          userDetails
        );
        if (response?.data?.message === "Data Insert Success") {
          setUserDetails({
            name: "",
            email: "",
            role: "",
            mobile_no: "",
            password: "",
            profilePic: "",
          });
          setOpen("User Created");
          fetchUsers();
          setCreateUser(false);
        } else {
          setErrMsg(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <div className="mt-4 font-roboto pb-8 border-b-blackSub border-b">
        <div className="flex items-start">
          <div className="relative">
            {showProgress ? (
              <div className="absolute flex inset-0 items-center justify-center">
                <CircularProgress size={24} />
              </div>
            ) : null}
            <img
              className="w-20 h-20 rounded-full object-cover"
              src={
                userDetails?.profilePic ||
                editUserDetails?.user_pic ||
                userPlaceholder
              }
              alt="user-profile"
            />
            <img
              onClick={() => imageRef.current.click()}
              className="absolute -top-2 left-[49px] p-1 cursor-pointer bg-white rounded-full bg-opacity-70"
              src={addImage}
              alt="add-image"
            />
          </div>
          <input
            onChange={handleImageChange}
            ref={imageRef}
            accept="image/*"
            type="file"
            className="hidden"
          />

          <div className="ml-4 w-[20%]">
            <p className="text-xs font-roboto">
              We recommended an image of at least 512x512 for the profile
              picture
            </p>
            <button
              className="font-medium test-sm hover:underline font-roboto mt-2 text-blackSub"
              onClick={handleRemoveImg}
            >
              Remove
            </button>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-wrap items-end gap-x-16 gap-y-4 mt-4"
        >
          <div className="mt-4 flex flex-col items-start gap-2">
            <label className="text-xs text-blackSub font-bold uppercase">
              Name
            </label>
            <input
              required
              name="name"
              onChange={handleInputChange}
              value={userDetails.name}
              type="text"
              placeholder="Enter name"
              className="w-[290px] py-[6px] pl-2 pr-4 text-sm rounded-sm border border-lightGrey focus:outline-none"
            />
          </div>
          <div className="mt-4 flex flex-col items-start gap-2">
            <label className="text-xs text-blackSub font-bold uppercase">
              Email
            </label>
            <input
              required
              name="email"
              onChange={handleInputChange}
              value={userDetails.email}
              type="email"
              placeholder="Enter email"
              className="w-[290px] py-[6px] pl-2 pr-4 text-sm rounded-sm border border-lightGrey focus:outline-none"
            />
          </div>
          <div className="mt-4 flex flex-col items-start gap-2">
            <label className="text-xs text-blackSub font-bold uppercase">
              Role
            </label>
            <input
              required
              name="role"
              onChange={handleInputChange}
              value={userDetails.role}
              type="text"
              placeholder="Enter role"
              className="w-[290px] py-[6px] pl-2 pr-4 text-sm rounded-sm border border-lightGrey focus:outline-none"
            />
          </div>
          <div className="mt-4 flex flex-col items-start gap-2">
            <label className="text-xs text-blackSub font-bold uppercase">
              Phone Number
            </label>
            <input
              required
              name="mobile_no"
              onChange={handleInputChange}
              value={userDetails.mobile_no}
              type="number"
              placeholder="Enter phone number"
              maxLength={10}
              className="w-[290px] py-[6px] pl-2 pr-4 text-sm rounded-sm border border-lightGrey focus:outline-none"
            />
          </div>
          <div className="mt-4 flex flex-col items-start gap-2">
            <label className="text-xs text-blackSub font-bold uppercase">
              Password
            </label>
            <div className="relative">
              <input
                required
                name="password"
                onChange={handleInputChange}
                value={userDetails.password}
                type={showPassword ? "text" : "password"} // Toggle between text and password
                placeholder="Enter password"
                className="w-[290px] py-[6px] pl-2 pr-4 text-sm rounded-sm border border-lightGrey focus:outline-none"
              />
              <button
                type="button"
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? (
                  <VisibilityOff /> // Eye icon for password visibility off
                ) : (
                  <Visibility /> // Eye icon for password visibility on
                )}
              </button>
            </div>
          </div>
          <div>
            <button
              type="button"
              onClick={handleReset}
              className="text-blackSubDark font-bold mx-10 text-xs"
            >
              Reset
            </button>
            <button
              type="submit"
              className="bg-buttonPrimary text-white px-10 py-2 rounded-[4px] font-semibold text-xs"
            >
              Save
            </button>
          </div>
        </form>
        {errMsg && (
          <p className="text-errorRed font-roboto font-medium text-sm text-center mt-6">
            {errMsg}
          </p>
        )}
      </div>
      {open === "User Created" && (
        <UpdateSuccessModal message="User created successfully." />
      )}
    </>
  );
};

export default CreateUser;
