import React, { useState, useContext } from "react";
import placeholder from "../assets/placeholder.png";
import editIcon from "../assets/edit.png";
import { DistrictsContext } from "../context/DistrictsContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IoIosClose } from "react-icons/io";

const WellDetailsCard = ({ details, type, item, edit }) => {
  const { editFormModal, setEditFormModal } = useContext(DistrictsContext);
  const [ModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const handleEditModal = (well) => {
    setEditFormModal(well);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setModalOpen(false);
    setSelectedImage("");
  };

  const wellDetails = [
    {
      label: "Well Type",
      value: details?.well_type || "-",
    },
    {
      label: "Village",
      value: details?.village || "-",
    },
    {
      label: "Location Details",
      value: details?.location_detail || "-",
    },
    {
      label: "Latitude",
      value: details?.latitude || "-",
    },
    {
      label: "Longitude",
      value: details?.longitude || "-",
    },
    ...(details?.well_type.toLowerCase().includes("piezometer")
      ? [
          {
            label: "Depth of Well (m)",
            value: details?.depth || "-",
          },
          {
            label: "Diameter",
            value: details?.diameter || "-",
          },
          {
            label: "Measuring Point",
            value: details?.measuring_point || "-",
          },
          {
            label: "Vendor",
            value: details?.vendor || "-",
          },
        ]
      : []),

    {
      label: "Map",
    },
    {
      label: "Photos",
      value: details?.survey_images || "-", // Assume this is an array
    },
  ];

  const historyWell = [
    {
      label: "Employee ID",
      value: item?.emp_id || "-",
    },
    {
      label: "Reported By",
      value: item?.name || "-",
    },
    {
      label: "Date Reported",
      value: details?.createdat || "-",
    },
    {
      label: "Village",
      value: details?.village || "-",
    },
    {
      label: "Location Details",
      value: item?.location_detail || "-",
    },
    {
      label: "Depth",
      value: item?.depth || "-",
    },
    {
      label: "Dia",
      value: item?.dia || "-",
    },
    {
      label: "MP",
      value: item?.mp || "-",
    },
    {
      label: "WL / mbmp",
      value: item?.wl_mbmp || "-",
    },
    {
      label: "Remarks",
      value: item?.remarks || "-",
    },
    {
      label: "Photos",
      value: item?.survey_images || "-",
    },
  ];

  const currentDetails = type === "history" ? historyWell : wellDetails;

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    if (isNaN(date)) {
      return "Invalid timestamp";
    }
    return date.toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <div className="h-[77vh] bg-white w-full overflow-y-auto custom-scrollbar">
      <div className="flex items-center justify-between pb-4 border-b-2">
        <div>
          <h4 className="font-poppins font-bold pt-4 text-buttonSecondary text-lg mb-2">
            Well id : {details?.ex_well_id}
          </h4>
          <h4 className="font-poppins font-bold text-buttonSecondary inline-block">
            Well Status <span className="mx-1">:</span>{" "}
            <span
              className={`${
                details?.status === "Active"
                  ? "text-successGreen "
                  : "text-errorRed"
              } font-bold text-sm`}
            >
              {details?.status}
            </span>
          </h4>
          {details.well_type.toLowerCase().includes("piezometer") ? (
            <h4 className="font-poppins font-bold text-buttonSecondary">
              Key No : {details?.key_no}
            </h4>
          ) : null}
        </div>
        {edit === false ? null : (
          <img
            onClick={() => handleEditModal(details)}
            className="cursor-pointer p-3 bg-[#F5F4F8] rounded-md mr-7"
            src={editIcon}
            alt="edit-icon"
          />
        )}
      </div>
      <div className="my-4 w-full">
        <div>
          {currentDetails.map((item, i) => (
            <div className="mb-3 font-roboto" key={i}>
              <p className="text-textGrey text-[10px] font-bold">
                {item?.label}
              </p>

              {item?.label === "Map" ? (
                <iframe
                  name="gMap"
                  style={{ width: "100%", height: "250px" }}
                  className="rounded-lg"
                  src={`https://www.google.com/maps/embed/v1/place?q=${details?.latitude},${details?.longitude}&key=AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE`}
                ></iframe>
              ) : null}
              <div className="flex items-center flex-wrap gap-7">
                {Array?.isArray(item?.value) && item?.label === "Photos" ? (
                  item?.value?.length > 0 &&
                  item?.value?.some((image) => image?.img?.length > 0) ? (
                    item?.value?.map((imageObj, index) =>
                      imageObj?.img.map((imgSrc, imgIndex) => (
                        <div
                          className="w-[124px] cursor-pointer"
                          key={`${index}-${imgIndex}`}
                        >
                          <img
                            className="w-full h-[70px] rounded-t-lg"
                            src={imgSrc || placeholder}
                            alt={`${item?.label}-${index}-${imgIndex}`}
                            onClick={() => handleImageClick(imgSrc)}
                          />
                          <p className="text-[13px] p-1 bg-[#E7ECEF] text-center rounded-b-lg">
                            {imageObj?.time
                              ? formatTimestamp(imageObj?.time)
                              : "No timestamp available"}
                          </p>
                        </div>
                      ))
                    )
                  ) : (
                    <p className="text-errorRed text-sm">No images available</p>
                  )
                ) : (
                  <p className="text-blackSubDark text-sm">{item?.value}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal to display selected image */}
      <Modal
        open={ModalOpen}
        onClose={handleCloseImageModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...style }}>
          <button
            onClick={handleCloseImageModal}
            className="absolute top-5 right-5 bg-black bg-opacity-50 rounded-full text-white p-1"
          >
            <IoIosClose className="text-3xl" />
          </button>
          <img className="" src={selectedImage} alt="Selected Image" />
        </Box>
      </Modal>
    </div>
  );
};

const style = {
  padding: "4rem 0",
  width: "auto",
  height: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default WellDetailsCard;
